import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {connect, useDispatch, useSelector} from 'react-redux';
import { Link } from 'react-router-dom';
import {
    Icon, Image, Label, Menu,
} from 'semantic-ui-react';
import { openMenu } from './actions';
import { getCart } from '../../views/Cart/reducer';
import './../NavTopBar/NavBar.css'

import TicketIcon from "./../../icons/ticket-icon.png";
import UsersIcon from "./../../icons/users-icon.png";
import AccessIcon from "./../../icons/access-icon.png";
import MenuIcon from "./../../icons/menu-icon.png";
import UserIcon from "../../icons/user-icon.png";
import {isMenuVisible} from "./reducer";
import {isSearchVisible} from "../NavTopBar/reducer";

export default function NavBottomBar(props) {

    const { cart, wishlist } = props;
    const dispatch = useDispatch()
    const searchVisible = useSelector((state) => isSearchVisible(state.navtopbar));
    const visible = useSelector((state) => isMenuVisible(state.navbottombar));

    function getCartQuantity() {
        return cart.reduce((quantity, item) => item.quantity + quantity, 0);
    }

    function showSidebar(e) {
        //const { openMenu: open } = props;
        e.stopPropagation();
        dispatch(openMenu())
        //console.log(visible)
    }


    return (
        <>
            {searchVisible ? '' : (
                <Menu
                    fluid
                    secondary
                    className="nav-bottom-bar"
                    fixed="bottom"
                    icon="labeled"
                    inverted
                >
                    <Link to="/" className="shop-link">
                        <Menu.Item className="shop-icon-item">
                            <Image src={TicketIcon} size="small" className="shop-icon-bottom" />
                            Билеты
                        </Menu.Item>
                    </Link>
                    <Link to="/orders" className="shop-link">
                        <Menu.Item className="shop-icon-item">
                            <Image src={UsersIcon} size="small" className="shop-icon-bottom" />
                            Посетители
                        </Menu.Item>
                    </Link>
                    <Link to="/check-access" className="shop-link">
                        <Menu.Item className="shop-icon-item">
                            <Image src={AccessIcon} size="small" className="shop-icon-bottom" />
                            Доступы
                        </Menu.Item>
                    </Link>
                    <Menu.Item className="shop-icon-item" onClick={showSidebar}>
                        <Image src={MenuIcon} size="small" className="shop-icon-bottom" />
                        Меню
                    </Menu.Item>
                </Menu>
            )}
        </>
    );
}

