import { combineReducers } from 'redux';
import {MENU_OPEN, MENU_CLOSE} from "./actions";

const sideMenu = (state = false, action = 0) => {
  switch (action.type) {
    case MENU_OPEN:
      return true;
    case MENU_CLOSE:
      return false;
    default:
      return state;
  }
};

export const isMenuVisible = (state) => state.sideMenu;
export default combineReducers({ sideMenu });
