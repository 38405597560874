import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './styles.css';

import { closeSearch } from '../../components/NavTopBar/actions';
import { isSearchVisible } from '../../components/NavTopBar/reducer';

import { toastr } from 'react-redux-toastr';

import { getLocationData } from '../../components/GeoLocation/reducer';
import GeoLocation from '../../components/GeoLocation/GeoLocation';
import {getAuthToken, getUserData} from "../UserLogin/reducer";
import {Button, Dimmer, Divider, Dropdown, Header, Icon, Image, Input, Loader, Modal, Segment} from "semantic-ui-react";

import {isNull} from "lodash";
import {getExpoCurrentLocation, getExpoLocations, isExpoLocationFetching} from "./reducer";
import {fetchExpoLocationData, setExpoLocationData} from "./actions";
import ProductCard from "../ProductCard";
import {useMixpanel} from "react-mixpanel-browser";
import {store} from "../../configureStore";

export default function ExpoLocation(props) {

    const searchVisible = useSelector((state) => isSearchVisible(state.navtopbar));
    const token  = useSelector((state) => getAuthToken(state.userLogin));
    const userData  = useSelector((state) => getUserData(state.userLogin));
    const userLocation  = useSelector((state) => getLocationData(state.userLocation));
    const currentExpoLocation  = useSelector((state) => getExpoCurrentLocation(state.expoLocation));
    const expoLocations  = useSelector((state) => getExpoLocations(state.expoLocation));
    const expoLocationsFetching  = useSelector((state) => isExpoLocationFetching(state.expoLocation));
    const mixpanel = useMixpanel();

    const dispatch = useDispatch();

    const [stateExpoLocation, setStateExpoLocation] = useState({
        isOpen: props.isOpen,
        accessEvent: currentExpoLocation.expoEvent,
        accessPoint: currentExpoLocation.expoAccessPoint,
        accessPointName: currentExpoLocation.expoAccessPointName,
    });

    useEffect(() => {
        if (searchVisible) {
            dispatch(closeSearch());
        }
        //console.log('ExpoLocation has mounted', props, stateExpoLocation);
        dispatch(fetchExpoLocationData(token));
        if (mixpanel)
            mixpanel.track('Expo Location View', { ...stateExpoLocation, ...userLocation, ...userData });
    }, []);

    let listEvents = [];
    if (expoLocations && expoLocations.events) {
        listEvents = expoLocations.events.map((element, index) => (
            {
                key: index,
                text: element,
                value: element,
            }
        ));
    }

    let listPoints = [];
    if (listEvents.length) {
        const currentEvent = (typeof stateExpoLocation.accessEvent === 'undefined' || typeof expoLocations.points[stateExpoLocation.accessEvent] === 'undefined')
            ? listEvents[0].value
            : stateExpoLocation.accessEvent,
            points = expoLocations.points[currentEvent];
       for (let index in points)
            listPoints.push({ key: index, text: points[index].name, value: index});
    }

    function closeModalLocation() {
        setStateExpoLocation({...stateExpoLocation, isOpen: false, isLoaded: false});
        props.closeFunction();
    }
    function saveExpoLocation() {
        dispatch(setExpoLocationData({
            expoEvent: stateExpoLocation.accessEvent,
            expoAccessPoint: stateExpoLocation.accessPoint,
            expoAccessPointName: expoLocations.points[stateExpoLocation.accessEvent][stateExpoLocation.accessPoint].name,
        }));

        mixpanel.track('Expo Location Set New', {
            ...stateExpoLocation, ...userLocation, ...userData,
        });

        closeModalLocation();
    }

   return (
    <div>
        <Modal
            closeIcon
            dimmer
            open={ stateExpoLocation.isOpen }
            centered
            size="fullscreen"
            closeOnDimmerClick={true}
            className="modal-expo-location"
            onOpen={ () => setStateExpoLocation({...stateExpoLocation, isOpen: true, isLoaded: false}) }
            onClose={ () => closeModalLocation()}
        >
            <Header icon="address card outline" content="Выберите событие и вашу локацию" />
            <GeoLocation />
            <Modal.Content>

                <Dimmer active={ expoLocationsFetching }>
                    <Loader>Запрашиваем события и локации ...</Loader>
                </Dimmer>
                <Dropdown
                    className="expo-location-events-list"
                    placeholder="Выберите событие ..."
                    selection
                    fluid
                    options={listEvents}
                    value={stateExpoLocation.accessEvent}
                    onChange={(event, data)=> setStateExpoLocation({...stateExpoLocation, accessEvent: data.value})}
                />
                <Dropdown
                    className="expo-location-events-list"
                    placeholder="Выберите локацию ..."
                    selection
                    fluid
                    options={listPoints}
                    value={stateExpoLocation.accessPoint}
                    onChange={(event, data)=> setStateExpoLocation({...stateExpoLocation, accessPoint: data.value, accessPointName: data.text})}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button
                    negative
                    onClick={closeModalLocation}>
                    Отмена
                </Button>
                <Button
                    color="red"
                    disabled={ isNull(stateExpoLocation.accessEvent) || isNull(stateExpoLocation.accessPoint) }
                    onClick={ () => saveExpoLocation() }
                >
                    Сохранить
                </Button>
            </Modal.Actions>
        </Modal>
    </div>
);
}