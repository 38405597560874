import React, {useEffect} from 'react';
import {Button, Card, Input} from 'semantic-ui-react';
import 'react-dadata/dist/react-dadata.css';
import {useDispatch, useSelector} from 'react-redux';

import './styles.css';
import { getCart } from '../../views/Cart/reducer';
import { getDiscount, getDiscountFetching } from './reducer';
import {fetchApplyCoupon} from "./actions";

export default function CouponCard(props)  {

    const dispatch = useDispatch();
    const cart = useSelector((state) => getCart(state.cart));
    const isFetching = useSelector((state) => getDiscountFetching(state.discount));
    const coupon = useSelector((state) => getDiscount(state.discount));

    const [state, setState] = React.useState({
        error: false,
        coupon: '',
    });


    useEffect(() => {
        setState({error: !!coupon.message, coupon: coupon.data && coupon.data.coupon ? coupon.data.coupon : ''})
    }, [coupon]);

    const setField = (e) => setState({...state, coupon: e.target.value});

    const applyCoupon = () => {
        dispatch(fetchApplyCoupon(state.coupon, JSON.stringify(cart)));
    }

    // console.log('промокод', coupon, cart, isFetching, state);

    return (
      <div className="coupon-card">
        <div className="coupon-card-content">
          <Input
            name="inputCoupon"
            placeholder={"Промокод"}
            className="coupon-apply-input"
            value={state.coupon}
            onChange={ setField }
            loading={ isFetching }
          />
            <Button className="coupon-card-button-confirm" content={"Применить"} onClick={ applyCoupon } />
        </div>
      </div>
    );
}

