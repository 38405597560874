import React, {useEffect, useRef, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMixpanel } from 'react-mixpanel-browser';

import {
    Card, Divider, Header, Icon, Loader, Button, Image, Container, Dimmer,
} from 'semantic-ui-react';
import {isSearchVisible} from "../../components/NavTopBar/reducer";
import {getAuthToken, getUserData} from "../../components/UserLogin/reducer";
import {getLocationData} from "../../components/GeoLocation/reducer";
import {closeSearch} from "../../components/NavTopBar/actions";
import Html5QrcodePlugin from "./Html5QrcodePlugin";
import GeoLocation from "../../components/GeoLocation/GeoLocation";
import {getTicketData, isTicketFetching} from "./reducer";
import {fetchTicketData, receiveTicketData} from "./actions";
import ExpoLocation from "../../components/ExpoLocation";
import {getExpoCurrentLocation} from "../../components/ExpoLocation/reducer";
import {Link} from "react-router-dom";
import EditIcon from "../../icons/access-checker-edit-icon.png"
import LocationIcon from "../../icons/access-check-location-icon.png"
import PhotoIcon from "../../icons/access-check-photo-icon.png"

import './styles.css';
import {toastr} from "react-redux-toastr";
import {isMobile} from 'react-device-detect';
import {isNull} from "lodash";

export default function AccessChecker(props) {

    const searchVisible = useSelector((state) => isSearchVisible(state.navtopbar));
    const token  = useSelector((state) => getAuthToken(state.userLogin));
    const userLocation  = useSelector((state) => getLocationData(state.userLocation));
    const userData  = useSelector((state) => getUserData(state.userLogin));
    const dispatch = useDispatch();
    const ticketLoading = useSelector((state) => isTicketFetching(state.ticket));
    const ticketData = useSelector((state) => getTicketData(state.ticket));
    const currentExpoLocation  = useSelector((state) => getExpoCurrentLocation(state.expoLocation));
    const mixpanel = useMixpanel();

    const [stateMode, setStateMode] = useState({
        mode: 'scanning',
        style: {display: 'block'},
    });

    const scannerRef = useRef(null);

    useEffect(() => {
        if (searchVisible) {
            dispatch(closeSearch());
        }
        dispatch(receiveTicketData([]));
        if (mixpanel)
            mixpanel.track('Scanner View', { ...stateMode, ...currentExpoLocation, ...userLocation, ...userData });
        window.scrollTo(0, 0);
    }, []);

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }
    const [stateQrCode, setStateQrCode] = useState("");
    const prevQrCode = usePrevious(stateQrCode);

    useEffect(() => {
        if (prevQrCode !== stateQrCode && stateQrCode.length) {

            isMobile && window.navigator.vibrate(100);

            dispatch(fetchTicketData(
                token,
                {
                    ticket: stateQrCode,
                    access_point: currentExpoLocation.expoAccessPoint,
                    access_event: currentExpoLocation.expoEvent,
                },
            ));

            setStateMode({style: {display: 'none'}, mode: 'checking'});

            mixpanel.track('Scanner New Result', {
                ...stateMode, ...userLocation, ...userData, ...currentExpoLocation,
                'qr-code-text': stateQrCode,
            });
        }
    }, [stateQrCode]);

    useEffect(() => {

        console.log('ticketData', ticketData, stateMode);

        if (mixpanel && stateMode.mode === 'checking' && ticketData.code)
            mixpanel.track(
                ticketData.code === 200
                    ? 'Scanner Check Success'
                    : 'Scanner Check Decline',
                { ...stateMode, ...currentExpoLocation, ...userLocation, ...userData, ... ticketData.qrData });
        if (stateMode.mode === 'checking' && ticketData.code === 200 && ticketData.output_mode === 0) {
            toastr.success('Событие сохранено', ticketData.qrData.MES);
            setStateMode({ mode: 'scanning', style: {display: 'block'} });
        }
    }, [ticketData]);

    function unicodeToChar(text) {
        return text.replace(/\\u[\dA-F]{4}/gi,
            function (match) {
                return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
            });
    }

    function onNewScanResult (decodedText, decodedResult)  { setStateQrCode(decodedText); }

    function onScanFailure(error) {
        mixpanel.track('Scanner New Error', { ...stateMode, ...userLocation, ...userData, ...currentExpoLocation, 'qr-scan-error': error });
        console.warn(`Code scan error = ${error}`);
    }

    function onButtonApproveContinue() {
        setStateMode({style: {display: 'block'}, mode: 'scanning'});
        dispatch(receiveTicketData([]));
    }

    function closeModalSetup() {
        setStateMode({style: {display: 'block'}, mode: 'scanning'});
    }

    if (!navigator.onLine) {
        return (<Container><p>Нет подключения к Интернету</p></Container>);
    }

    if (!token) {
        return (
            <Container><p>Для сканирования бейджа необходимо авторизоваться и войти в систему</p></Container>
        );
    }

    return (
        <div>
            <Header className={'access-checker-header'}>Сканирование</Header>
            <Card centered raised className={'access-checker-card'}>
               <Header as='h3' color="blue" className="scanner-header">
                    <Image src={LocationIcon} style={{width:'35px', marginLeft:'7px'}} />
                    <Header.Content>
                        <span style={{color:'#B10000', fontSize:'15px'}}>Моя локация</span>
                        <Header.Subheader style={{fontSize:'10px'}}>
                            { currentExpoLocation.expoEvent ?? 'Событие и локация не заданы' }
                            <br/>
                            { currentExpoLocation.expoAccessPointName ?? ''}
                        </Header.Subheader>
                        <Button
                            className="auther-location-edit-button"
                            onClick={ () => setStateMode({style: {display: 'none'}, mode: 'setup'}) }
                            icon={
                                <Image src={EditIcon}/>}
                        />
                    </Header.Content>
                </Header>
                <GeoLocation className={'access-checker-geolocation'}/>
            </Card>
            <Dimmer active={ ticketLoading } inverted>
                <Loader inverted>Проверяем QR-код ...</Loader>
            </Dimmer>
            { !ticketLoading && stateMode.mode === 'checking' && (ticketData.code === 400 || ticketData.output_mode === 1)
                ? <Card centered color="teal">
                    <Card.Content>
                        <Card.Header>
                            { ticketData.qrData.URL !== ''
                                ? <Image
                                    floated="left"
                                    size="medium"
                                    src= { ticketData.qrData.URL }
                                />
                                : '' }
                            <div className="check-ticket-name">{ ticketData.qrData.LST + ' ' + ticketData.qrData.FST }</div>
                            <div className="check-ticket-job-title">{ ticketData.qrData.PJT }</div>
                        </Card.Header>
                        <Card.Meta>{ ticketData.qrData.PRT }</Card.Meta>
                        <Divider />
                        <Card.Meta>{ ticketData.qrData.EVT  }</Card.Meta>
                        <Link to={'/orders/' + ticketData.qrData.ORI}>
                            <div color="blue" className="check-ticket-order-number">{ "Билет № " + ticketData.qrData.JTI  }</div>
                        </Link>
                        <Card.Description>
                            <strong>{ ticketData.qrData.PRD }</strong>
                            <br/>
                        </Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                        <strong>{ ticketData.message }</strong>
                        <div className='ui two buttons'>
                            { ticketData.code === 200 ?
                                <Button fluid color='green' onClick={onButtonApproveContinue}>
                                    Добро пожаловать!
                                </Button> :
                                <Button color='red' onClick={onButtonApproveContinue}>
                                    Проверка не пройдена!
                                </Button>
                            }
                        </div>
                    </Card.Content>
                </Card>

                : <Card centered color="orange" className="scanner-card">
                    <Header as='h3' color="blue" className="scanner-reader">
                        <Image src={PhotoIcon} style={{width:'35px', marginLeft:'7px'}}/>
                        <Header.Content style={{color:'#B10000', fontSize:'15px'}}>
                            Отсканируйте бейдж
                            <Header.Subheader style={{fontSize:'10px'}}>наведите на QR код для считывания</Header.Subheader>
                        </Header.Content>
                    </Header>

                    <Html5QrcodePlugin
                        forwardRef = {scannerRef}
                        aspectRatio={4/3}
                        fps={25}
                        //qrbox={stateQrBox}
                        disableFlip={false}
                        qrCodeSuccessCallback={onNewScanResult}
                        verbose={false}
                    />
                </Card>
            }
            {stateMode.mode === 'setup' ?
                <ExpoLocation isOpen={true}  closeFunction={closeModalSetup}/> : ''
            }
        </div>
    );
}
