import React from 'react';
import { Button } from 'semantic-ui-react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate}  from 'react-router-dom';

import { getShippingMethod, getShippingType } from '../ShippingCard/reducer';

import {
    ADDRESS_ADDINFO,
    ADDRESS_ADDRESS,
    ADDRESS_CITY,
    ADDRESS_COMPANY,
    ADDRESS_COMPANY_ADDR, ADDRESS_COMPANY_CHIEF_NAME, ADDRESS_COMPANY_CHIEF_POST,
    ADDRESS_COMPANY_INN,
    ADDRESS_COMPANY_JOB_TITLE, ADDRESS_COMPANY_KPP, ADDRESS_COMPANY_OGRN,
    ADDRESS_COMPANY_SCOPE,
    ADDRESS_COUNTRY,
    ADDRESS_EMAIL,
    ADDRESS_FIRSTNAME,
    ADDRESS_GEOLAT,
    ADDRESS_GEOLON,
    ADDRESS_LASTNAME,
    ADDRESS_PHONE,
    ADDRESS_PHONE_COUNTRY,
    ADDRESS_POSTCODE,
    ADDRESS_STATE,
} from '../AddressCard/actions';
import { getAddressFields} from '../AddressCard/reducer';
import {getAuthToken, getUserData} from "../UserLogin/reducer";
import {getLocationData} from "../GeoLocation/reducer";
import {getExpoCurrentLocation} from "../ExpoLocation/reducer";
import {getPlaceOrderInProgress} from "../../views/Orders/reducer";
import {getPaymentMethod} from "../../views/Checkout_2_StepPayment/reducer";
import {getCartItems, hasOnlyVirtuals} from "../../views/Cart/reducer";
import {putOrder} from "../../views/Orders/actions";
import {useMixpanel} from "react-mixpanel-browser";
import {getDiscount} from "../CouponCard/reducer";
import {toastr} from "react-redux-toastr";

export default function ConfirmOrderButton(props) {

    const dispatch = useDispatch();
    const cart_items = useSelector((state) => getCartItems(state.cart));
    const only_virtuals = useSelector((state) => hasOnlyVirtuals(state.cart));

    const shipping_type = only_virtuals? '' : useSelector((state) => getShippingType(state.shipping));
    const shipping_method = only_virtuals ? '' : useSelector((state) => getShippingMethod(state.shipping));
    const address_fields = useSelector((state) => getAddressFields(state.address));
    const payment_option = useSelector((state) => getPaymentMethod(state.payment));

    const token  = useSelector((state) => getAuthToken(state.userLogin));
    const userData  = useSelector((state) => getUserData(state.userLogin));
    const userLocation  = useSelector((state) => getLocationData(state.userLocation));
    const currentExpoLocation  = useSelector((state) => getExpoCurrentLocation(state.expoLocation));

    const discount = useSelector((state) => getDiscount(state.discount));
    const coupon = (discount.data && discount.data.coupon ? discount.data.coupon : '');

    const mixpanel = useMixpanel();

    const navigate = useNavigate();

    const orderData = {

        user_location: JSON.stringify({
            userData: {id: userData.id, login: userData.login},
            userLocation,
            currentExpoLocation
        }),

        company_meta: JSON.stringify(address_fields[ADDRESS_COMPANY_ADDR]),

        payment_option,

        coupon,

        lang: 'ru',

        create_account: 1,

        cart_items: JSON.stringify(cart_items),

        billing: JSON.stringify({
            first_name: address_fields[ADDRESS_FIRSTNAME],
            last_name: address_fields[ADDRESS_LASTNAME],
            country: address_fields[ADDRESS_COUNTRY],
            state: address_fields[ADDRESS_STATE],
            phone: address_fields[ADDRESS_PHONE],
            email: address_fields[ADDRESS_EMAIL],
            phone_country: address_fields[ADDRESS_PHONE_COUNTRY],

            is_juridic: !!address_fields[ADDRESS_COMPANY],
            company: address_fields[ADDRESS_COMPANY],
            company_job_title: address_fields[ADDRESS_COMPANY_JOB_TITLE],
            company_law_address: address_fields[ADDRESS_COMPANY_ADDR] ?? '',
            company_inn: address_fields[ADDRESS_COMPANY_INN] ?? '',
            company_kpp: address_fields[ADDRESS_COMPANY_KPP] ?? '',
            company_ogrn: address_fields[ADDRESS_COMPANY_OGRN] ?? '',
            company_chief_post: address_fields[ADDRESS_COMPANY_CHIEF_POST] ?? '',
            company_chief_name: address_fields[ADDRESS_COMPANY_CHIEF_NAME] ?? '',
        })
    };

    console.log('order data', orderData);

    function handleClick() {

        if (!token) {
            toastr.error("Для создания заказа, пожалуйста, авторизуйтесь!");
            return;
        }

        dispatch(putOrder(token, orderData, navigate, props.pathToRoute));

        if (mixpanel)
            mixpanel.track('Checkout Place Order', { ...currentExpoLocation, ...userLocation, ...userData, payment_option, ...address_fields, cart_items });
    }

    return (
        <Button
            color="green"
            className= {`${props.addClassName} button-confirm`}
            onClick={handleClick}
        >
            {props.buttonText}
        </Button>
    );
}



