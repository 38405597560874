import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
    Image, Menu, Input, Form, Icon,
} from 'semantic-ui-react';
import config from '../../config/config';
import {
    openSearch, openAuth, closeSearch,
} from './actions';
import { isAuthVisible, isSearchVisible } from './reducer';
import './NavBar.css';
import UserLogin from '../UserLogin/index';
import {getUserLoggedIn, getUserDisplayName, getUserData} from '../UserLogin/reducer';
import {getLocationData} from "../GeoLocation/reducer";
import {getExpoCurrentLocation} from "../ExpoLocation/reducer";
import {useMixpanel} from "react-mixpanel-browser";
import {isMobile} from 'react-device-detect';
import { ReactComponent as AsiaexpoIcon} from './../../icons/asiaexpo-icon.svg'
import UserIcon from "./../../icons/user-icon.png";
import SearchIcon from "./../../icons/search-icon.png";

export default function NavTopBar(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const searchVisible = useSelector((state) => isSearchVisible(state.navtopbar));
    const authVisible = useSelector((state) => isAuthVisible(state.navtopbar));
    const isLoggedIn =  useSelector((state) => getUserLoggedIn(state.userLogin));
    const userNickName = useSelector((state) => getUserDisplayName(state.userLogin));

    const userData  = useSelector((state) => getUserData(state.userLogin));
    const userLocation  = useSelector((state) => getLocationData(state.userLocation));
    const currentExpoLocation  = useSelector((state) => getExpoCurrentLocation(state.expoLocation));
    const mixpanel = useMixpanel();

    const [state, setState] = useState({search: '',});

    /**
     * Handle search form submit.
     * Set state for redirecting to search page and close search box.
     */
    function handleSubmit() {
        setState({ ...state, search: '' });
        dispatch(closeSearch());
        navigate(`/search/${state.search}`, { state: { doQuery: true }});
        if (mixpanel)
            mixpanel.track('Search Confirm', { ...currentExpoLocation, ...userLocation, ...userData, ...state });
    }

    function setSearch(e) {
        setState({ ...state, search: e.target.value });
    }

    /**
     * Open search box when icon is clicked.
     * Reset search input and redirect when the search is opened.
     */
    function handleOpenSearch() {
        const { open } = props;
        setState({ ...state, search: '' });
        dispatch(openSearch());
        if (mixpanel)
            mixpanel.track('Search Open', { ...currentExpoLocation, ...userLocation, ...userData });
    }

    function handleOpenAuth() {
        dispatch(openAuth());
        if (mixpanel)
            mixpanel.track('Auth Open Form', { ...currentExpoLocation, ...userLocation, ...userData });
    }

    const { search: searchIt } = state;

    return (
        <Menu fluid secondary fixed="top" className="nav-top-bar" icon="labeled" inverted>
            <Menu.Item className="shop-name">
                <Link to="/">
                    <AsiaexpoIcon className="ae-icon"/>
                </Link>
            </Menu.Item>

            {searchVisible ? null : (
                <Menu.Item className="shop-icon-item first-right">
                    <Image src={SearchIcon} size="small" className="shop-icon" onClick={handleOpenSearch} />
                    Поиск
                </Menu.Item>
            )}
            {!searchVisible && isLoggedIn ? (
                <Menu.Item className="shop-icon-item second-right">
                    <Image src={UserIcon} size="small" className="shop-icon" />
                    {userNickName}
                </Menu.Item>
            ) : null}
            {!searchVisible && !isLoggedIn ? (
                <Menu.Item className="shop-icon-item second-right">
                    <Icon name="sign in" size="small" className="shop-icon" onClick={handleOpenAuth} />
                    Войти
                </Menu.Item>
            ) : null}
            <UserLogin showModal={authVisible} />
            {searchVisible === false ? null : (
                <Menu.Item className="menu-search-item">
                    <Form onSubmit={handleSubmit}>
                        <Input
                            name="search"
                            type="text"
                            className="search"
                            value={searchIt}
                            onChange={setSearch}
                            placeholder="Поиск"
                            icon="search"
                            autoFocus={true}
                        />
                    </Form>
                </Menu.Item>
            )}
        </Menu>
    );
}
