import { combineReducers } from 'redux';
import _ from 'lodash';
import {
  CHECKOUT_INSERT_ORDER,
  CHECKOUT_ORDER_PLACED, ORDERS_PAGE_COUNT, RECEIVE_1PAGE_ORDERS,
  RECEIVE_ORDER_DATA, RECEIVE_ORDERS,
  REQUEST_ORDER_UPDATE,
  REQUEST_ORDERS
} from "./actions";
import {RECEIVE_1PAGE_PRODUCTS, REQUEST_PRODUCTS} from "../Home/actions";
import {RECEIVE_SEARCH_ORDERS} from "../Search/actions";

const placedOrder = (state = 0, data = {}) => {
  switch (data.type) {
    case CHECKOUT_INSERT_ORDER:
      return data.orderData;
      case CHECKOUT_ORDER_PLACED:
        return data.order;
    default:
      return state;
  }
};

const isOrderDMLProgress = (state = 0, data = {}) => {
  switch (data.type) {
    case CHECKOUT_INSERT_ORDER:
      return 1;
    case REQUEST_ORDER_UPDATE:
      return data.order_id;
    case CHECKOUT_ORDER_PLACED:
    case RECEIVE_ORDER_DATA:
      return 0;
    default:
      return state;
  }
};

const isOrdersFetching = (state = 0, data = {}) => {
  switch (data.type) {
    case CHECKOUT_INSERT_ORDER:
    case REQUEST_PRODUCTS:
      return true;
    case CHECKOUT_ORDER_PLACED:
    case RECEIVE_ORDERS:
    case RECEIVE_1PAGE_PRODUCTS:
      return false;
    default:
      return state;
  }
};

const items = (state = [], action = 0) => {
  switch (action.type) {
    case REQUEST_ORDERS:
      return state;
    case RECEIVE_ORDERS:
    case RECEIVE_SEARCH_ORDERS:
      if (Array.isArray(action.orders)) return _.unionBy(action.orders, state, 'id');
      return _.unionBy([action.orders], state, 'id');
    case RECEIVE_1PAGE_ORDERS:
      return action.orders;
    case CHECKOUT_ORDER_PLACED:
    case RECEIVE_ORDER_DATA:
      if (_.isEmpty(action.order))
        return state;
      return _.unionBy([action.order], state, 'id');
    default:
      return state;
  }
};

const hasMore = (state = false, action) => {
  switch (action.type) {
    case REQUEST_ORDERS:
      return true;
    case RECEIVE_ORDERS:
    case RECEIVE_1PAGE_ORDERS:
      return action.orders.length >= ORDERS_PAGE_COUNT;
    default:
      return state;
  }
};

export const getOrders = (state) => state.items;
export const getPlacedOrder = (state = {}) => state.placedOrder;
export const getPlaceOrderInProgress = (state = {}) => state.isOrderCreateProgress;
export const getOrdersFetching = (state = {}) => state.isOrdersFetching;
export const getOrdersHasMore = (state) => state.hasMore;

const orders = combineReducers({
  placedOrder, isOrderCreateProgress: isOrderDMLProgress, isOrdersFetching, items, hasMore,
});
export default orders;
