import React, {useState} from 'react';

import {
    Button,
    Header, Label,
} from 'semantic-ui-react';
import ModalPrompt from "../ModalPrompt";
import {useDispatch, useSelector} from "react-redux";
import {getAuthToken, getUserData} from "../UserLogin/reducer";
import {getLocationData} from "../GeoLocation/reducer";
import {updateOrder} from "../../views/Orders/actions";
import {getExpoCurrentLocation} from "../ExpoLocation/reducer";
import {useMixpanel} from "react-mixpanel-browser";


export default function OrderCardHeader(props) {

    const {order, actionButtons} = props;
    const {status, id} = order;
    const [open, setOpen] = React.useState(false);

    const token = useSelector((state) => getAuthToken(state.userLogin));
    const dispatch = useDispatch();
    const userData  = useSelector((state) => getUserData(state.userLogin));
    const userLocation  = useSelector((state) => getLocationData(state.userLocation));
    const currentExpoLocation  = useSelector((state) => getExpoCurrentLocation(state.expoLocation));
    const mixpanel = useMixpanel();


    const orderDate = (order.date_created.date ?? order.date_created).slice(0, 10);

    function showPrompt() {
        setOpen(true);
        if (mixpanel)
            mixpanel.track('OrderCard Status Prompt', { ...currentExpoLocation, ...userLocation, ...userData, status });
    }

    function orderState() {
        const data = {status: status === 'cancelled'? 'undo' : 'cancelled'};
        dispatch(updateOrder(token, id,
            JSON.stringify(data)
        ));
        setOpen(false);

        if (mixpanel)
            mixpanel.track('OrderCard Status Change', { ...currentExpoLocation, ...userLocation, ...userData, ...data });
    }

    return (
        <div>

                <div className='number-of-order-text' dangerouslySetInnerHTML={{__html: '№' + String(id) + ' от ' + orderDate}}/>
                {/*{actionButtons ?*/}
                {/*    <Button className="order-mini-card-cancel-button" icon={icon} onClick={showPrompt}/> : ''}*/}



            <ModalPrompt
                open={open}
                headerIcon="question"
                headerText="Подтверждение"
                messageText={
                    status === 'cancelled'
                        ? `Вы хотите восстановить заказ №${id}?`
                        : `Действительно отменить заказ №${id}?`
                }
                handleYes={orderState}
                handleNo={() => setOpen(false)}
                handleClose={() => setOpen(false)}
                handleOpen={() => setOpen(true)}
            />
        </div>
    );
}