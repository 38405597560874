import React, {useEffect} from 'react';
import {Header, Button, Card, Loader, Dimmer} from 'semantic-ui-react';
import {useDispatch, useSelector} from 'react-redux';
import {Link}  from 'react-router-dom';

import './styles.css';
import CardSummary from '../../components/CardSummary/CardSummary';
import CheckoutSteps, { CHECKOUT_STEP_PAYMENT } from '../../components/CheckoutSteps';
import {getTotalPrice, hasOnlyVirtuals} from '../Cart/reducer';
import { isSearchVisible } from '../../components/NavTopBar/reducer';
import { getShippingCost, getShippingMethod, getShippingType } from '../../components/ShippingCard/reducer';
import PaymentOptions from './PaymentOptions';

import { getAddressFields} from '../../components/AddressCard/reducer';
import { getPaymentMethod } from './reducer';
import CouponCard from '../../components/CouponCard';
import {closeSearch} from "../../components/NavTopBar/actions";
import ConfirmOrderButton from "../../components/OrderCard/ConfirmOrderButton";
import {getUserData} from "../../components/UserLogin/reducer";
import {getLocationData} from "../../components/GeoLocation/reducer";
import {getExpoCurrentLocation} from "../../components/ExpoLocation/reducer";
import {useMixpanel} from "react-mixpanel-browser";
import {getPlaceOrderInProgress} from "../Orders/reducer";
import {getDiscount} from "../../components/CouponCard/reducer";
import {removeCoupon} from "../../components/CouponCard/actions";
import {receiveOrderData} from "../Orders/actions";
import {store} from "../../configureStore";

export default function CheckoutStepPayment(props) {

  const dispatch = useDispatch();
  const searchVisible = useSelector((state) => isSearchVisible(state.navtopbar));

    const discount = useSelector((state) => getDiscount(state.discount));
    const total_products = useSelector((state) => getTotalPrice(state.cart)) -
        (discount.data && discount.data.discount ? discount.data.discount : 0);

    const total_shipping = useSelector((state) => getShippingCost(state.shipping));
    const total_price = total_products + (total_shipping ?? 0);

    const coupon = useSelector((state) => getDiscount(state.discount));
    const couponCode = coupon?.data?.coupon ?? ''

  const only_virtuals = useSelector((state) => hasOnlyVirtuals(state.cart));

  const shipping_type = only_virtuals? '' : useSelector((state) => getShippingType(state.shipping));
  const shipping_method = only_virtuals ? '' : useSelector((state) => getShippingMethod(state.shipping));
  const address_fields = useSelector((state) => getAddressFields(state.address));
  const payment_option = useSelector((state) => getPaymentMethod(state.payment));
  const getLoading = useSelector((state) => getPlaceOrderInProgress(state.orders));

  const userData  = useSelector((state) => getUserData(state.userLogin));
  const userLocation  = useSelector((state) => getLocationData(state.userLocation));
  const currentExpoLocation  = useSelector((state) => getExpoCurrentLocation(state.expoLocation));
  const mixpanel = useMixpanel();

  useEffect(() => {
    if (searchVisible) {
      dispatch(closeSearch());
    }
      if (mixpanel)
          mixpanel.track('Checkout Payment', { ...currentExpoLocation, ...userLocation, ...userData, payment_option, address_fields, total_products, total_shipping });

      dispatch(removeCoupon(couponCode));

      dispatch(receiveOrderData([]));

  }, []);

  console.log('global redux states', store.getState());
  console.log('CheckoutStepPayment', discount, total_price)

  return (
      <div>
        <Header textAlign="center">Выбор способа оплаты</Header>
        {/*<CheckoutSteps step={CHECKOUT_STEP_PAYMENT} />*/}
        <Dimmer active={ getLoading === 1 } inverted>
            <Loader inverted> Создаем заказ ...</Loader>
        </Dimmer>
        <CardSummary
          total_products={total_products}
          total_shipping={total_shipping}
          show_shipping = {!only_virtuals}
        />
        <CouponCard />
          {total_price > 0 ?
            <Card centered className="delivery-details-card">
              {/*<Card.Header style={{paddingLeft: '10px'}}>Способ оплаты</Card.Header>*/}
              <Card.Content>
                    <PaymentOptions
                      paymentOption={payment_option}
                      shippingMethod={shipping_method}
                      shippingType={shipping_type}
                      emailField={address_fields.email ?? ''}
                      dispatch={dispatch}
                    />
          </Card.Content>
        </Card> : ''}
        <div className="checkout-payment-buttons">
          <Link to="/checkout-step-shipping">
            <Button secondary className="checkout-payment-buttons-button button-back" floated="right">
              Назад
            </Button>
          </Link>
          <ConfirmOrderButton
              addClassName="checkout-payment-buttons-button"
              buttonText="Подтвердить заказ"
              pathToRoute="/checkout-step-confirm"
          />
        </div>
      </div>
    );
  }



