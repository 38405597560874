import React from 'react';
import {toastr} from "react-redux-toastr";
import {addProduct, removeAllProductsFromCart} from "../views/Cart/actions";
import {useDispatch, useSelector} from "react-redux";
import {getCart} from "../views/Cart/reducer";
import {getUserData} from "./UserLogin/reducer";
import {getLocationData} from "./GeoLocation/reducer";
import {getExpoCurrentLocation} from "./ExpoLocation/reducer";
import {useMixpanel} from "react-mixpanel-browser";
import {useNavigate} from "react-router";
import './../views/Home/style.css'
import {
    ADDRESS_COMPANY,
    ADDRESS_COMPANY_ADDR,
    ADDRESS_COMPANY_CHIEF_NAME,
    ADDRESS_COMPANY_CHIEF_POST,
    ADDRESS_COMPANY_INN,
    ADDRESS_COMPANY_JOB_TITLE,
    ADDRESS_COMPANY_KPP,
    ADDRESS_COMPANY_OGRN,
    ADDRESS_COUNTRY,
    ADDRESS_EMAIL,
    ADDRESS_FIRSTNAME,
    ADDRESS_LASTNAME,
    ADDRESS_PHONE,
    ADDRESS_PHONE_COUNTRY,
    ADDRESS_STATE,
    setAddressFields
} from "./AddressCard/actions";
import {Button, Divider} from "semantic-ui-react";
import config from "../config/config";

export default function ProductCard(props) {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart =  useSelector( (state) => getCart(state.cart));

  const {
    categories: propCat, id, src, categoryId, name, shortDescription, price, has_options, virtual
  } = props;

  const userData  = useSelector((state) => getUserData(state.userLogin));
  const userLocation  = useSelector((state) => getLocationData(state.userLocation));
  const currentExpoLocation  = useSelector((state) => getExpoCurrentLocation(state.expoLocation));
  const mixpanel = useMixpanel();

  function addItem() {

    // все удаляем из корзины
    dispatch(removeAllProductsFromCart());
    // очищаем старые адресняе данные
    dispatch(setAddressFields(
        {
          [ADDRESS_FIRSTNAME]: '',
          [ADDRESS_LASTNAME]: '',

          [ADDRESS_COMPANY]: '',
          [ADDRESS_COMPANY_JOB_TITLE]: '',
          [ADDRESS_COMPANY_ADDR]: '',
          [ADDRESS_COMPANY_INN]: '',
          [ADDRESS_COMPANY_KPP]: '',
          [ADDRESS_COMPANY_OGRN]: '',
          [ADDRESS_COMPANY_CHIEF_POST]: '',
          [ADDRESS_COMPANY_CHIEF_NAME]: '',

          [ADDRESS_COUNTRY]: 'RU',
          [ADDRESS_STATE]: 'RU-N/D',
          [ADDRESS_PHONE_COUNTRY]: 'RU',
          [ADDRESS_PHONE]: '',
          [ADDRESS_EMAIL]: '',
        }
    ));
    // добавляем один лишь продукт
    dispatch(
        addProduct(id,
            name,
            price,
            src,
            virtual,
            null,
            null,
        ),
    );

    toastr.success('Оформление', name + ' начинаем оформление');

    if (mixpanel)
      mixpanel.track('Add To Cart', { ...currentExpoLocation, ...userLocation, ...userData, id, product_name: name, price, src, virtual, });

    navigate("/checkout-step-shipping");

  }


    const parts = name.split('-');
    const title = parts[0].trim();
    const visitorText = parts[1] ? parts[1].trim() : '';

  return (
      <div className='order-card-container'>
          <div className='order-card-top-box'>
              <img src={src} className='order-card-img'/>
              <div className='order-card-content'>
                    <span className='order-card-title-event'>
                        {title}
                        <br/>
                        <span className='visitor-text-order'>{visitorText.toUpperCase()}</span>
                    </span>
              </div>
          </div>
          <div className='order-card-divider'></div>
          <span className='order-card-desc-event' dangerouslySetInnerHTML={{__html: shortDescription}}></span>
          <div className='order-card-bottom-box'>
              <Button className='button-ticket-order' onClick={addItem}>
                  Оформить билет
              </Button>
              <span className='order-card-price-text'>{price} {config.CURRENCY}</span>
          </div>
      </div>
  );
}
