import { createStore, applyMiddleware } from 'redux';
import { persistCombineReducers, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { reducer as toastr } from 'react-redux-toastr';
import thunk from 'redux-thunk';
import { createMemoryHistory } from 'history';
import { routerMiddleware } from 'react-router-redux';

import config from './config/config';
import categories from './views/Categories/reducer';
import products from './views/Home/reducer';
import reviews from './components/Reviews/reducer';
import cart from './views/Cart/reducer';
import variations from './components/Variations/reducer';
import search from './views/Search/reducer';
import navtopbar from './components/NavTopBar/reducer';
import navbottombar from './components/NavBottomBar/reducer';
import address from './components/AddressCard/reducer';
import shipping from './components/ShippingCard/reducer';
import payment from './views/Checkout_2_StepPayment/reducer';
import discount from './components/CouponCard/reducer';
import userLogin from './components/UserLogin/reducer';
import userLocation from './components/GeoLocation/reducer';
import ticket from './views/AccessChecker/reducer';
import expoLocation from "./components/ExpoLocation/reducer";
import orders from './views/Orders/reducer';

const rootPersistConfig = {
  key: 'root',
  storage,
  blacklist: [
        'navtopbar',
        'navbottombar',
        'search',
        'toastr',
  ],
   debug: true,
};

const rootReducer = persistCombineReducers(rootPersistConfig, {
  categories: persistReducer(
    {
      key: 'categories',
      storage,
      blacklist: config.OFFLINE ? ['isFetching', 'hasMore'] : ['isFetching', 'hasMore'],
    },
    categories,
  ),
  products: persistReducer(
    {
      key: 'products',
      storage,
      blacklist: config.OFFLINE ? ['isFetching', 'hasMore'] : ['isFetching', 'hasMore'],
    },
    products,
  ),
  reviews: persistReducer(
    {
      key: 'reviews',
      storage,
      blacklist: config.OFFLINE ? ['isFetching'] : ['isFetching'],
    },
    reviews,
  ),
  variations: persistReducer(
    {
      key: 'variations',
      storage,
      blacklist: config.OFFLINE ? ['isFetching'] : ['isFetching'],
    },
    variations,
  ),
  cart: persistReducer(
    {
      key: 'cart',
      storage,
    },
    cart,
  ),
  navtopbar,
  navbottombar,
  search,
  toastr,
  address: persistReducer(
    {
      key: 'address',
      storage,
    },
    address,
  ),
  shipping: persistReducer(
    {
      key: 'shipping',
      storage,
    },
    shipping,
  ),
  payment: persistReducer(
    {
      key: 'payment',
      storage,
    },
    payment,
  ),
  discount: persistReducer(
    {
      key: 'discount',
      storage,
    },
    discount,
  ),
  userLogin: persistReducer(
    {
      key: 'userLogin',
      storage,
    },
    userLogin,
  ),
  userLocation: persistReducer(
    {
      key: 'userLocation',
      storage,
    },
    userLocation,
  ),
  ticket: persistReducer(
        {
            key: 'ticket',
            storage,
        },
        ticket,
  ),
  expoLocation: persistReducer(
      {
          key: 'expoLocation',
          storage,
      },
      expoLocation,
  ),
    orders: persistReducer(
        {
            key: 'orders',
            storage,
        },
        orders,
    ),
});

const history = createMemoryHistory();

export const store = createStore(
  rootReducer,
  undefined,
  applyMiddleware(thunk, routerMiddleware(history)),
);

export const persistor = persistStore(store);

export { history };
