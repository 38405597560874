import React, { Component } from 'react';
import ReduxToastr from 'react-redux-toastr';
import PropTypes from 'prop-types';
import {connect, useDispatch, useSelector} from 'react-redux';
import { Sidebar, Sticky } from 'semantic-ui-react';
import { useNavigate, useParams } from 'react-router';

import {MixpanelProvider, useMixpanel} from 'react-mixpanel-browser';

import { bindActionCreators } from 'redux';
import { isMenuVisible } from './components/NavBottomBar/reducer';
import { closeMenu } from './components/NavBottomBar/actions';
import NavTopBar from './components/NavTopBar';
import NavBottomBar from './components/NavBottomBar';
import SideMenu from './views/SideMenu';

import './App.css';
import { dropToken } from './components/UserLogin/actions';
import {getAuthToken, getUserData, getUserLoggedIn} from './components/UserLogin/reducer';
import {MIXPANEL_API_KEY} from "./config/secrets";
import {isSearchVisible} from "./components/NavTopBar/reducer";
import {getLocationData} from "./components/GeoLocation/reducer";
import _, {isArray} from "lodash";
import {closeSearch} from "./components/NavTopBar/actions";
import {store} from "./configureStore";

const MIXPANEL_TOKEN = MIXPANEL_API_KEY;
const MIXPANEL_CONFIG = {
  track_pageview: true, // Set to `false` by default
};

export default function App (props) {

  const defaultProps = {
    navigate: useNavigate(),
    dispatch: useDispatch(),
    searchVisible: useSelector((state) => isSearchVisible(state.navtopbar)),
    menuVisible: useSelector((state) => isMenuVisible(state.navbottombar)),
    userData: useSelector((state) => getUserData(state.userLogin)),
    userLocation: useSelector((state) => getLocationData(state.userLocation)),
    isLoggedIn: useSelector((state) => getUserLoggedIn(state.userLogin)),
    token: useSelector((state) => getAuthToken(state.userLogin)),
    mixpanel: useMixpanel(),
  }

  const {routeAction, location, previousLocations, menuVisible, searchVisible, dispatch} = defaultProps;

  if ( process.env.REACT_APP_ENV !== 'production') {
    console.log('App - глобальные стейты REDUX', store.getState());
    console.log('App - router-redux action, location, previous', routeAction, location, previousLocations);
  }

  const {children} = props;
  const sideMenuVisible = useSelector((state) => isMenuVisible(state.navbottombar));

  function hideSidebar() {
    if (sideMenuVisible) {
      dispatch(closeMenu());
    }
    if (searchVisible) {
      dispatch(closeSearch());
    }
  }

  return (
      <MixpanelProvider config={MIXPANEL_CONFIG} token={MIXPANEL_TOKEN}>
      <div>
        <ReduxToastr
          timeOut={4000}
          newestOnTop
          preventDuplicates
          position="top-center"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
        />
        <NavTopBar  {...defaultProps}/>
        <Sidebar.Pushable style={{ transform: 'none' }}>
          <Sticky>
            <SideMenu  {...defaultProps}/>
          </Sticky>
          <Sidebar.Pusher
            dimmed={sideMenuVisible || searchVisible}
            onClick={hideSidebar}
            style={{ minHeight: '100vh' }}
          >
            {children}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
        <NavBottomBar  {...defaultProps}/>
      </div>
      </MixpanelProvider>
    );
  }
