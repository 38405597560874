import { combineReducers } from 'redux';
import { REQUEST_APPLY_COUPON, RECEIVE_APPLY_COUPON, REMOVE_COUPON } from './actions';

const isFetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST_APPLY_COUPON:
      return true;
    case RECEIVE_APPLY_COUPON:
      return false;
    default:
      return state;
  }
};


const coupon = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_APPLY_COUPON:
      return state;
    case RECEIVE_APPLY_COUPON:
      return action.couponData;
    case REMOVE_COUPON:
      return {}
    default:
      return state;
  }
};

export const getDiscount = state => state.coupon;
export const getDiscountFetching = state => state.isFetching;

const discount = combineReducers({
  isFetching,
  coupon,
});

export default discount;
