import { combineReducers } from 'redux';
import _ from 'lodash';
import {
  REQUEST_SEARCH_PRODUCTS,
  RECEIVE_SEARCH_PRODUCTS,
  RESET_SEARCH_PRODUCTS,
  REQUEST_SEARCH_ORDERS, RECEIVE_SEARCH_ORDERS, RESET_SEARCH_ORDERS
} from './actions';
import {ORDERS_PAGE_COUNT} from "../Orders/actions";
import {PAGE_COUNT} from "../Home/actions";

const items = (state = [], action) => {
  switch (action.type) {
    case REQUEST_SEARCH_PRODUCTS:
    case REQUEST_SEARCH_ORDERS:
      return state;
    case RECEIVE_SEARCH_PRODUCTS:
      if (Array.isArray(action.products)) {
        return _.unionBy(action.products, state, 'id');
      }
      return _.unionBy([action.products], state, 'id');
    case RECEIVE_SEARCH_ORDERS:
      if (Array.isArray(action.orders)) {
        return _.unionBy(action.orders, state, 'id');
      }
      return _.unionBy([action.orders], state, 'id');
    case RESET_SEARCH_PRODUCTS:
    case RESET_SEARCH_ORDERS:
      return [];
    default:
      return state;
  }
};

const isFetching = (state = 0, action) => {
  switch (action.type) {
    case REQUEST_SEARCH_PRODUCTS:
    case REQUEST_SEARCH_ORDERS:
      return state + 1;
    case RECEIVE_SEARCH_PRODUCTS:
    case RECEIVE_SEARCH_ORDERS:
      return state - 1;
    case RESET_SEARCH_ORDERS:
    case RESET_SEARCH_PRODUCTS:
      return 0;
    default:
      return state;
  }
};

const hasMore = (state = false, action) => {
  switch (action.type) {
    case REQUEST_SEARCH_PRODUCTS:
    case REQUEST_SEARCH_ORDERS:
      return true;
    case RECEIVE_SEARCH_PRODUCTS:
      return action.products.length >= PAGE_COUNT;
    case RECEIVE_SEARCH_ORDERS:
      return action.orders.length >= ORDERS_PAGE_COUNT;
    default:
      return state;
  }
};

export const getSearch = state => state.items;
export const getSearchFetching = state => state.isFetching;

export const getHasMore = state => state.hasMore;

export default combineReducers({
  items,
  isFetching,
  hasMore,
});
