import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Header } from 'semantic-ui-react';
import ProductCard from './ProductCard';
import { productPropType } from '../views/Home/reducer';

export default function ProductsList(props) {

    const { products, title } = props;

    const list = products.map((element, index) => (
      <ProductCard
        key={element.id}
        id={element.id}
        src={element.images[0].src}
        name={element.name}
        price={element.price}
        categories={element.categories}
        shortDescription={element.short_description}
        categoryId={element.categories[0].id}
        has_options={element.has_options}
        virtual={element.virtual}
      />
    ));

    return (
      <div>
        <Header textAlign="center">{title}</Header>
        {list}
      </div>
    );

}

// ProductsList.propTypes = {
//   products: PropTypes.arrayOf(productPropType).isRequired,
//   title: PropTypes.string.isRequired,
// };
//
// export default ProductsList;
